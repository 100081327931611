import { NextPage } from 'next';
import Link from 'next/link';

const NotFound: NextPage = () => (
  <div className="text-center mt-52">
    <h2 className="inline-block text-4xl text-gray-700">404</h2>
    <div className="text-lg mt-3 mb-2">Page Not Found</div>
    <div className="mb-6">
      The page you are looking for does not seem to exist
    </div>
    <Link href="/" className="text-blue-700">
      Go to Dashboard
    </Link>
  </div>
);

export default NotFound;
